/* eslint-disable prettier/prettier */
import { Button, Icon } from '@sellpy/design-system-react-web'
import styled, { css } from 'styled-components'
import { mediaQueries } from '../../../../uiComponents/mediaQueries'

export const StyledSearch = styled('search')`
  z-index: 4003;
  margin: auto;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.color.white.default};
  border-radius: ${({ theme }) => theme.radius.soft}px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  ${mediaQueries.largerThanTablet} {
    padding-bottom: ${({ theme }) => theme.spacing.compact.val2}px;
    padding-top: ${({ theme }) => theme.spacing.compact.val12}px;
    padding-right: ${({ theme }) => theme.spacing.regular.val16}px;
    padding-left: ${({ theme }) => theme.spacing.regular.val16}px;
    margin-top: 0px;
  }

  ${mediaQueries.tablet} {
    margin-top: 8px;
    ${({ isFocused }) =>
      isFocused &&
      css`
        position: absolute;
        display: flex;
        background-color: ${({ theme }) => theme.color.white.default};
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
        border-radius: 8px;
      `}
  }
`

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;
  border-radius: 999px;
  border-style: solid;
  border-width: ${({ theme, isFocused }) =>
    isFocused ? theme.strokeWidth.prominent : theme.strokeWidth.subtle}px;
  border-color: ${({ theme }) => theme.colour.stroke.interactive.nr100.default};
  background-color: ${({ theme }) => theme.colour.bg.interactive.nr300.default};

  ${mediaQueries.tablet} {
    margin: ${({ isFocused }) => (isFocused ? '0 16px' : 0)};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colour.bg.interactive.nr300.hover};
  }
`

export const StyledInput = styled.input`
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  border: none;
  margin: 0;
  height: ${({ isFocused }) => (isFocused ? '38px' : '40px')};
  width: ${({ isFocused }) => (isFocused ? 'calc(100%-2px)' : 'auto')};

  flex-grow: 1;
  font-family: inherit;
  font-size: 14px;
  outline: none;
  min-width: 0;
  text-indent: ${({ removeSpace, isFocused }) => (!removeSpace ? isFocused ? '39px' : '40px' : isFocused && '8px')};
  border-radius: 999px;

  ::-webkit-search-decoration {
    display: none;
  }
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`

export const ClearButton = styled.button`
  border: none;
  cursor: pointer;
  margin: 0;
  padding: ${({ isLastButton }) => (isLastButton ? '0 16px 0 8px' : '0 8px 0 8px')};
  min-width: 30px;
  outline: none;
  align-items: center;
  background-color: transparent;
`

export const CancelButton = styled.button`
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0px 8px 0 16px;
  min-width: 30px;
  outline: none;
  align-items: center;
  background-color: transparent;
`

export const Cover = styled.div`
  z-index: 4000;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  min-width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  overflow: hidden;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
    `}
`

export const IconContainer = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  margin: ${({ borderIncreaseRight }) => borderIncreaseRight && `0 0 0 -1px`};
`

export const IconWithColor = styled(Icon)`
  font-size: 24px;
  margin-top: -2px;
  color: ${({ theme }) => theme.colour.text.interactive.nr100.default};
`

export const RoundButton = styled(Button)`
  border-radius: 50%;
  ${mediaQueries.largerThanTablet} {
    margin: ${({ increaseBorderFromRight }) => increaseBorderFromRight && `-1px -1px -1px 0`};
  }
`
